<template>
	<div class="main">
		<div class="menu">
			<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
				background-color="rgb(250, 250, 250)">
				<el-menu-item index="1">备货建议</el-menu-item>
			</el-menu>
		</div>
		<div class="select_menu">
			<!-- <el-input v-model="input" placeholder="搜索标题和id" style="width: 10%;"></el-input> -->
			<el-input v-model="query" placeholder="请输入父体名称" style="width: 200px; margin-left: 10px;"></el-input>
			<el-select v-model="filterText" placeholder="请选择店铺" style="margin-left: 10px;width:130px">
				<el-option v-for="item in filters" :key="item.value" :label="item.text" :value="item.value">
				</el-option>
			</el-select>
			<el-button style="margin-left: 10px;" @click="init" :loading="isSending">获取本地父体</el-button>
			<el-input placeholder="请输入本地品名" prefix-icon="el-icon-search" v-model="select_input"
				style="margin-left: 50px;width: 13%;">
			</el-input>
			<el-button style="margin-left: 5px;" @click="select_pm" :loading="isSending8">搜索</el-button>
		</div>
		<div class="parent_calculate">
			<el-button style="float: right;margin-top: -15px;margin-right: 10px;" @click="allupdate">批量编辑</el-button>
			<el-button style="float: right;margin-top: -15px;margin-right: 10px;" @click="auto_caigou" :loading="isSending18">一键采购</el-button>
			<div class="parent_calculate_div1">
				<div class="parent_calculate_table1">
					<el-table :data="tableData" style="width: 100%" :show-header="false" height="100%" size>
						<el-table-column width="15">
						</el-table-column>
						<el-table-column prop="本地父体" label="本地父体">
							<template slot-scope="scope">
								<div slot="reference" class="name-wrapper" @click="jojo(scope.row.本地父体)"
									@contextmenu.prevent="open(scope.row.本地父体)" v-if="scope.row.是否收藏 == '否'">
									{{ scope.row.本地父体 }}
								</div>
								<div slot="reference" class="name-wrapper" @click="jojo(scope.row.本地父体)"
									@contextmenu.prevent="open1(scope.row.本地父体)" v-if="scope.row.是否收藏 == '是'">
									*{{ scope.row.本地父体 }}
								</div>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
			<div class="parent_calculate_div1">
				<div class="parent_calculate_table2">
					<el-table :data="tableData1" style="width: 100%" height="100%" border>
						<el-table-column prop="本地父体" label="本地父体" width="168" fixed="left">
							<template slot-scope="scope">
								{{ scope.row.本地父体 }}
							</template>
						</el-table-column>
						<el-table-column prop="本地品名" label="本地品名" width="168" fixed="left">
							<template slot-scope="scope">
								<div slot="reference" class="name-wrapper" style="padding-top: 5px;">
									{{ scope.row.本地品名 }}
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="FBA库存" label="FBA库存" width="100">
							<template slot-scope="scope">
								<div slot="reference" class="name-wrapper" style="padding-top: 5px;">
									{{ scope.row.FBA库存 }}
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="FBA在途" label="FBA在途" width="100">
							<template slot-scope="scope">
								<div slot="reference" class="name-wrapper" style="padding-top: 5px;">
									{{ scope.row.FBA在途 }}
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="本地库存" label="本地库存" width="100">
							<template slot-scope="scope">
								<div slot="reference" class="name-wrapper" style="padding-top: 5px;">
									{{ scope.row.本地库存 }}
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="预计库存" label="预计库存" width="100">
							<template slot-scope="scope">
								<div slot="reference" class="name-wrapper" style="padding-top: 5px;">
									{{ scope.row.预计库存 }}
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="三十天销量" label="30天销量" width="110" sortable>
							<template slot-scope="scope">
								<div slot="reference" class="name-wrapper" style="padding-top: 5px;">
									{{ scope.row.三十天销量 }}
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="十四天销量" label="14天销量" width="110" sortable>
							<template slot-scope="scope">
								<div slot="reference" class="name-wrapper" style="padding-top: 5px;">
									{{ scope.row.十四天销量 }}
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="七天销量" label="7天销量" width="100" sortable>
							<template slot-scope="scope">
								<div slot="reference" class="name-wrapper" style="padding-top: 5px;">
									{{ scope.row.七天销量 }}
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="sku" label="sku" width="100">
							<template slot-scope="scope">
								<div slot="reference" class="name-wrapper" style="padding-top: 5px;">
									{{ scope.row.sku }}
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="fnsku" label="fnsku" width="100">
							<template slot-scope="scope">
								<div slot="reference" class="name-wrapper" style="padding-top: 5px;">
									{{ scope.row.fnsku }}
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="每个ASIN采购数量" label="每个ASIN采购数量" width="100">
							<template slot-scope="scope">
								<div slot="reference" class="name-wrapper" style="padding-top: 5px;">
									{{ scope.row.每个ASIN采购数量 }}
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="MSKU模版" label="MSKU模版" width="100">
							<template slot-scope="scope">
								<div slot="reference" class="name-wrapper" style="padding-top: 5px;">
									{{ scope.row.MSKU模版 }}
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="供应商" label="供应商" width="100">
							<template slot-scope="scope">
								<div slot="reference" class="name-wrapper" style="padding-top: 5px;">
									{{ scope.row.供应商 }}
								</div>
							</template>
						</el-table-column>
						<el-table-column fixed="right" prop="会员日备货" label="会员日备货" width="100">
							<template slot-scope="scope">
								<div slot="reference" class="name-wrapper" style="padding-top: 5px;">
									{{ scope.row.会员日备货 }}
								</div>
							</template>
						</el-table-column>
						<el-table-column fixed="right" prop="黑五备货" label="黑五备货" width="100">
							<template slot-scope="scope">
								<div slot="reference" class="name-wrapper" style="padding-top: 5px;">
									{{ scope.row.黑五备货 }}
								</div>
							</template>
						</el-table-column>
						<el-table-column fixed="right" prop="旺季备货" label="旺季备货" width="100">
							<template slot-scope="scope">
								<div slot="reference" class="name-wrapper" style="padding-top: 5px;">
									{{ scope.row.旺季备货 }}
								</div>
							</template>
						</el-table-column>
						<el-table-column fixed="right" prop="备货建议" label="备货建议" width="110" sortable>
							<template slot-scope="scope">
								<div slot="reference" class="name-wrapper" style="padding-top: 5px;"
									v-if="scope.row.会员日备货 === 0 && scope.row.黑五备货 === 0 && scope.row.旺季备货 === 0 && scope.row.备货天数 === 0">
									{{ scope.row.备货建议 = 0 }}
								</div>
								<!-- 根据不同的建议方案计算 -->
								<div slot="reference" class="name-wrapper" style="padding-top: 5px;" v-else>
									{{ scope.row.备货建议 = calculateSuggestion(scope.row) }}
								</div>
							</template>
						</el-table-column>
						<el-table-column fixed="right" label="操作" width="170">
							<template slot-scope="scope">
								<el-button size="small" :loading="isSending4" plain style="float: right;margin-left: 3px;"
									@click="update(scope.row)">编辑
								</el-button>
								<el-button size="small" :loading="isSending5" plain style="float: right;margin-left: 3px;"
								v-if="scope.row.采购开关 === '不启用一键采购'"
									@click="caigou1(scope.row)">启动采购
								</el-button>
								<el-button size="small" :loading="isSending5" plain style="float: right;margin-left: 3px;" type="danger"
								v-else-if="scope.row.采购开关 === '启用一键采购'"
									@click="caigou2(scope.row)">关闭采购
								</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div>&nbsp;</div>
				<div style="width: 500px;">tip:备货天数常态为90天，当90天计算备货建议为正时则以120天计算;</div>
				<!-- <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;备货天数常态为90天，当备货建议为正时为120天</div> -->
			</div>
		</div>
		<el-dialog :title="title" :visible="dialogVisible1" width="750px" :before-close="handleClose1">
			<el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
				:inline="true">
				<el-tooltip :content="'备货公式: ' + form.建议方案" placement="top">
					<el-switch v-model="form.建议方案" active-color="#13ce66" inactive-color="#ff4949"
						active-value="30天销量/30*（备货天数+旺季备货天数+黑五备货天数+会员日备货天数）-FBA库存-FBA在途-预计库存" active-text="建议方案二"
						inactive-text="建议方案一" inactive-value="30天销量/30*备货天数+旺季备货+黑五备货+会员日备货-FBA库存-FBA在途-预计库存">
					</el-switch>
				</el-tooltip>
			</el-form>
			<div>---</div>
			<el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
				:inline="true">
				<el-form-item label="会员日备货" prop="会员日备货" label-width="100px">
					<el-input v-model="form.会员日备货" placeholder="请输入会员日备货(只能填写数字)" style="width: 50%;" />
				</el-form-item>
				<el-form-item label="*会员日备货天数" prop="会员日备货天数" label-width="120px">
					<el-input v-model="form.会员日备货天数" placeholder="请输入会员日备货天数(只能填写数字)" style="width: 75%" />
				</el-form-item>
			</el-form>
			<el-form :model="form" status-icon :rules="rules" ref="form" label-width="110px" label-position="left"
				:inline="true">
				<el-form-item label="黑五备货" prop="黑五备货" label-width="100px">
					<el-input v-model="form.黑五备货" placeholder="请输入黑五备货(只能填写数字)" style="width: 50%;" />
				</el-form-item>
				<el-form-item label="*黑五备货天数" prop="黑五备货天数" label-width="110px">
					<el-input v-model="form.黑五备货天数" placeholder="请输入黑五备货天数(只能填写数字)" style="width: 80%;" />
				</el-form-item>
			</el-form>
			<el-form :model="form" status-icon :rules="rules" ref="form" label-width="110px" label-position="left"
				:inline="true">

				<el-form-item label="旺季备货" prop="旺季备货" label-width="100px">
					<el-input v-model="form.旺季备货" placeholder="请输入旺季备货(只能填写数字)" style="width: 50%;" />
				</el-form-item>
				<el-form-item label="*旺季备货天数" prop="旺季备货天数" label-width="110px">
					<el-input v-model="form.旺季备货天数" placeholder="请输入旺季备货天数(只能填写数字)" style="width: 80%;" />
				</el-form-item>
			</el-form>
			<el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
				:inline="true">
				<el-tooltip :content="'' + form.采购开关" placement="top">
					<el-switch v-model="form.采购开关" active-color="#13ce66" inactive-color="#ff4949" active-value="启用一键采购"
						inactive-text="采购开关" inactive-value="不启用一键采购">
					</el-switch>
				</el-tooltip>
			</el-form>
			<div>---</div>
			<el-form :model="form" status-icon :rules="rules" ref="form" label-width="110px" label-position="left"
				:inline="true">
				<el-form-item label="SKU" prop="SKU">
					<el-select v-model="form.sku" clearable placeholder="请选择SKU" style="width: 60%;" filterable>
						<el-option v-for="item in sku" :key="item.value" :label="item.sku" :value="item.value"
							autocomplete="off">
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
				:inline="true">
				<el-tooltip :content="'' + form.是否使用新ASIN" placement="top">
					<el-switch v-model="form.是否使用新ASIN" active-color="#13ce66" inactive-color="#ff4949"
						active-value="工具自动生成新ASIN" inactive-text="是否使用新ASIN" inactive-value="工具使用目前选择FNSKU"> ">
					</el-switch>
				</el-tooltip>
			</el-form>
			<div>---</div>
			<el-form :model="form" status-icon :rules="rules" ref="form" label-width="110px" label-position="left"
				:inline="true" v-if="form.是否使用新ASIN === '工具使用目前选择FNSKU'">
				<el-form-item label="FNSKU" prop="FNSKU">
					<el-select v-model="form.fnsku" clearable placeholder="请选择FNSKU" style="width: 70%;" filterable>
						<el-option v-for="item in fnsku" :key="item.value" :label="item.fnsku" :value="item.value"
							autocomplete="off">
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<el-form :model="form" status-icon :rules="rules" ref="form" label-width="110px" label-position="left"
				:inline="true" v-else-if="form.是否使用新ASIN === '工具自动生成新ASIN'">
				<el-form-item label="每个ASIN采购数量" prop="每个ASIN采购数量" label-width="130px">
					<el-input v-model="form.每个ASIN采购数量" placeholder="只能填写数字" style="width: 60%;" />
				</el-form-item>
				<el-form-item label="MSKU模版" prop="MSKU模版" label-width="100px">
					<el-input v-model="form.MSKU模版" placeholder="请输入MSKU模版" style="width: 80%;" />
				</el-form-item>
				<el-form-item label="供应商" prop="供应商" label-width="100px">
					<el-input v-model="form.供应商" placeholder="请输入供应商" style="width: 80%;" />
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="reset">重置</el-button>
				<el-button type="primary" @click="update_data" :loading="isSending2">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog :title="title" :visible="dialogVisible2" width="40%" :before-close="handleClose2">
			<el-form :model="form1" status-icon :rules="rules" ref="form1" label-width="100px" label-position="left"
				:inline="true">
				<el-tooltip :content="'备货公式: ' + form1.建议方案" placement="top">
					<el-switch v-model="form1.建议方案" active-color="#13ce66" inactive-color="#ff4949"
						active-value="30天销量/30*（备货天数+旺季备货天数+黑五备货天数+会员日备货天数）-FBA库存-FBA在途-预计库存" active-text="建议方案二"
						inactive-text="建议方案一" inactive-value="30天销量/30*备货天数+旺季备货+黑五备货+会员日备货-FBA库存-FBA在途-预计库存">
					</el-switch>
				</el-tooltip>
			</el-form>
			<div>---</div>
			<el-form :model="form1" status-icon :rules="rules" ref="form1" label-width="100px" label-position="left"
				:inline="true">
				<el-form-item label="会员日备货" prop="会员日备货" label-width="100px">
					<el-input v-model="form1.会员日备货" placeholder="请输入会员日备货(只能填写数字)" style="width: 70%;" />
				</el-form-item>
				<el-form-item label="*会员日备货天数" prop="会员日备货天数" label-width="120px">
					<el-input v-model="form1.会员日备货天数" placeholder="请输入会员日备货天数(只能填写数字)" style="width: 75%" />
				</el-form-item>
			</el-form>
			<el-form :model="form1" status-icon :rules="rules" ref="form1" label-width="110px" label-position="left"
				:inline="true">
				<el-form-item label="黑五备货" prop="黑五备货" label-width="100px">
					<el-input v-model="form1.黑五备货" placeholder="请输入黑五备货(只能填写数字)" style="width: 70%;" />
				</el-form-item>
				<el-form-item label="*黑五备货天数" prop="黑五备货天数" label-width="110px">
					<el-input v-model="form1.黑五备货天数" placeholder="请输入黑五备货天数(只能填写数字)" style="width: 80%;" />
				</el-form-item>
			</el-form>
			<el-form :model="form1" status-icon :rules="rules" ref="form1" label-width="110px" label-position="left"
				:inline="true">
				<el-form-item label="旺季备货" prop="旺季备货" label-width="100px">
					<el-input v-model="form1.旺季备货" placeholder="请输入旺季备货(只能填写数字)" style="width: 70%;" />
				</el-form-item>
				<el-form-item label="*旺季备货天数" prop="旺季备货天数" label-width="110px">
					<el-input v-model="form1.旺季备货天数" placeholder="请输入旺季备货天数(只能填写数字)" style="width: 80%;" />
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="reset">重置</el-button>
				<el-button type="primary" @click="updateall_data" :loading="isSending2">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
  
<script>
export default {
	data() {
		return {
			query: '',  // 本地父体搜索条件
			input: '',
			activeIndex: '1',
			activeIndex2: '1',
			bottercold: '',
			isSending: false,
			isSending1: false,
			isSending2: false,
			isSending4: false,
			isSending5: false,
			isSending8: false,
			isSending18: false,
			isSending_in: false,
			isSending_register: false,
			dialogVisible: false,
			dialogVisible1: false,
			dialogVisible2: false,
			projectname: '',
			projectf: '',
			select_input: '',
			isButtonDisabled: false,
			fileData: [],
			form: {},
			form1: {
				建议方案: '30天销量/30*备货天数+旺季备货+黑五备货+会员日备货-FBA库存-FBA在途-预计库存'
			},
			size: '',
			title: '',
			visible: false,
			tableData: [],
			tableData1: [],
			tableData2: [],
			tableDataover: [],
			sku: [],
			fnsku: [],
			selectedDate: '',
			num1: 1,
			filters: [{ text: 'CoBak-US', value: 'CoBak-US' }, { text: 'CoBak-UK', value: 'CoBak-UK' }, { text: 'CoBak-SG', value: 'CoBak-SG' }, { text: 'CoBak-SE', value: 'CoBak-SE' }, { text: 'CoBak-PL', value: 'CoBak-PL' }, { text: 'CoBak-NL', value: 'CoBak-NL' }, { text: 'CoBak-MX', value: 'CoBak-MX' }, { text: 'CoBak-JP', value: 'CoBak-JP' }, { text: 'CoBak-IT', value: 'CoBak-IT' }, { text: 'CoBak-INDIA-IN', value: 'CoBak-INDIA-IN' }, { text: 'CoBak-FR', value: 'CoBak-FR' }, { text: 'CoBak-ES', value: 'CoBak-ES' }, { text: 'CoBak-DE', value: 'CoBak-DE' }, { text: 'CoBak-CA', value: 'CoBak-CA' }, { text: 'CoBak-BR', value: 'CoBak-BR' }, { text: 'CoBak Direct-BE', value: 'CoBak Direct-BE' }, { text: 'SMIFACE-CA', value: 'SMIFACE-CA' }, { text: 'SMIFACE-US', value: 'SMIFACE-US' }, { text: 'IDLEHANDS-US-CA', value: 'IDLEHANDS-US-CA' }, { text: 'iDLEHANDS-US-MX', value: 'iDLEHANDS-US-MX' }, { text: 'iDLEHANDS-US-US', value: 'iDLEHANDS-US-US' }],
			filterText: '',
			value: '',
			visible1: false,
			value1: [],
			value2: [],
			value3: [],
			formLabelWidth: '80px',
			timer: null,
			named: '',
			fand: 0,
			store: [],
			flagnum: 0,
		}
	},
	created() {
		this.select_sku()
	},
	methods: {
		open(male_parent) {
			this.$confirm('此操作将收藏该本地父体, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$message({
					type: 'success',
					message: '收藏成功!'
				})
				this.$axios.post('/vue/parent_calculate/menu_collect', { male_parent: male_parent }).then(res => {
					console.log(res)
					this.init()
				})
					.console(error => {
						console.log(error)
						alert('发生错误:==' + error)
					})
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消操作'
				})
			})
		},
		open1(male_parent) {
			this.$confirm('此操作将取消收藏该本地父体, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$message({
					type: 'success',
					message: '取消收藏成功!'
				})
				this.$axios.post('/vue/parent_calculate/menu_unbind', { male_parent: male_parent }).then(res => {
					console.log(res)
					this.init()
				})
					.console(error => {
						console.log(error)
						alert('发生错误:==' + error)
					})
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消操作'
				})
			})
		},
		isInSeason(type) {
			const today = new Date();
			const year = today.getFullYear();
			let startDate, endDate;

			if (type === '旺季') {
				startDate = new Date(`${year}-08-01`)
				endDate = new Date(`${year}-11-30`)
			} else if (type === '黑五') {
				startDate = new Date(`${year}-08-01`)
				endDate = new Date(`${year}-11-30`)
			} else if (type === '会员日') {
				const startDate1 = new Date(`${year}-04-15`)
				const endDate1 = new Date(`${year}-07-15`)
				const startDate2 = new Date(`${year}-08-01`)
				const endDate2 = new Date(`${year}-10-15`)

				return (today >= startDate1 && today <= endDate1) || (today >= startDate2 && today <= endDate2);
			}

			return today >= startDate && today <= endDate;
		},
		calculateSuggestion(row) {
			let baseDays = row.备货天数;  // 默认使用备货天数
			// 根据不同的建议方案计算备货建议
			let suggestion;
			if (row.建议方案 === '30天销量/30*备货天数+旺季备货+黑五备货+会员日备货-FBA库存-FBA在途-预计库存') {
				suggestion = Math.round(
					Number(row.三十天销量) / 30 * baseDays
					+ (this.isInSeason('旺季') ? Number(row.旺季备货) : 0)
					+ (this.isInSeason('黑五') ? Number(row.黑五备货) : 0)
					+ (this.isInSeason('会员日') ? Number(row.会员日备货) : 0)
					- Number(row.FBA库存)
					- Number(row.FBA在途)
					- Number(row.预计库存)
				);
				// 如果备货建议为正数，将备货天数设为 120，并重新计算
			if (suggestion > 0) {
				baseDays = 120;
				suggestion = Math.round(
					Number(row.三十天销量) / 30 * baseDays
					+ (this.isInSeason('旺季') ? Number(row.旺季备货) : 0)
					+ (this.isInSeason('黑五') ? Number(row.黑五备货) : 0)
					+ (this.isInSeason('会员日') ? Number(row.会员日备货) : 0)
					- Number(row.FBA库存)
					- Number(row.FBA在途)
					- Number(row.预计库存)
				);
			}

			return suggestion;
			} else if (row.建议方案 === '30天销量/30*（备货天数+旺季备货天数+黑五备货天数+会员日备货天数）-FBA库存-FBA在途-预计库存') {
				suggestion = Math.round(
					Number(row.三十天销量) / 30 *
					(baseDays +
						(this.isInSeason('旺季') ? Number(row.旺季备货天数) : 0) +
						(this.isInSeason('黑五') ? Number(row.黑五备货天数) : 0) +
						(this.isInSeason('会员日') ? Number(row.会员日备货天数) : 0))
					- Number(row.FBA库存)
					- Number(row.FBA在途)
					- Number(row.预计库存)
				);
				// 如果备货建议为正数，将备货天数设为 120，并重新计算
			if (suggestion > 0) {
				baseDays = 120;
				suggestion = Math.round(
					Number(row.三十天销量) / 30 *
					(baseDays +
						(this.isInSeason('旺季') ? Number(row.旺季备货天数) : 0) +
						(this.isInSeason('黑五') ? Number(row.黑五备货天数) : 0) +
						(this.isInSeason('会员日') ? Number(row.会员日备货天数) : 0))
					- Number(row.FBA库存)
					- Number(row.FBA在途)
					- Number(row.预计库存)
				);
			}
			return suggestion;
			}
		},
		init() {
			this.isSending = true
			let params = {
				query: this.query
			}
			this.$axios.get('/vue/auto_update/seller_asin_select', { params }).then(res => {
				console.log(res);
				if (res.msg == 'success') {
					this.tableData = res.data
					this.isSending = false
				} else {
					alert(res.message)
					this.isSending = false
				}
			})
				.console(error => {
					console.log(error)
					alert('发生错误:==' + error)
				})
		},
		select_sku() {
			this.$axios.get('/vue/parent_calculate/select_sku').then(res => {
				if (res.msg == 'success') {
					this.sku = res.data
				} else {
					console.log(res.data)
				}
			}).console(error => {
				console.log(error)
				alert('发生错误:==' + error)
			})
		},
		handleSelect(key, keyPath) {
			console.log(key, keyPath);
		},
		update(row) {
			this.title = row.本地品名
			this.form = {}
			this.form = row
			this.dialogVisible1 = true
			this.select_fnsku(row.本地品名, this.filterText)
		},
		select_fnsku(pm, shop) {
			this.$axios.post('/vue/parent_calculate/select_fnsku', { pm: pm, shop: shop }).then(res => {
				if (res.msg == 'success') {
					this.fnsku = res.data
				} else {
					console.log(res.data)
				}
			}).console(error => {
				console.log(error)
				alert('发生错误:==' + error)
			})
		},
		auto_caigou(){
			this.isSending18 = true
			setTimeout(() => {
				alert("该点击指令已生效，请勿重复点击。")
				console.log(this.tableData1)
            }, 6000);
			this.$axios.post('/vue/parent_calculate/auto_caigou', this.tableData1).then(res => {
				if (res.msg == '正在进行中') {
					alert("该父体一键采购正在进行中")
					this.isSending18 = false
				} else {
					alert("该父体一键采购已在进行中,请勿重复操作")
					this.isSending18 = false
				}
			}).console(error => {
				console.log(error)
				alert('发生错误:==' + error)
				this.isSending18 = false
			})
		},
		handlePreview(file) {
			console.log(file);
		},
		handleClose() {
			this.dialogVisible = false
			this.init()
		},
		handleClose1() {
			this.dialogVisible1 = false
			this.jojo(this.form.本地父体)
		},
		handleClose2() {
			this.dialogVisible2 = false
			this.jojo(this.projectf)
		},
		reset() {
			let id = undefined
			if ('id' in this.form) {
				id = this.form.id
			}
			this.form = {}
			if (id != undefined) this.form.id = id
		},
		jojo(project) {
			if (this.filterText == '') {
				alert('请选择店铺')
			} else {
				this.$axios.post('/vue/parent_calculate/parent_select_child', { name: project, shop: this.filterText }).then(res => {
					console.log(res);
					if (res.msg == 'success') {
						this.tableData1 = res.data
						this.projectf = project
					} else {
						alert('未获取到该父体的品名数据')
					}
				})
					.console(error => {
						console.log(error)
						alert('发生错误:==' + error)
					})
			}
		},
		caigou1(row){
			this.isSending5 = true
			this.$axios.post('/vue/parent_calculate/caigou1', row).then(res => {
				if (res.code == 200) {
					let _this = this
					this.$notify.success({
						title: '成功',
						message: res.msg,
						duration: 2000,
						onClose: function () { _this.handleClose2() }
					});
					this.isSending5 = false
				} else {
					this.$notify.error({
						title: '错误',
						message: res.msg,
						duration: 2000
					});
					this.isSending5 = false
				}
			})
				.catch(error => {
					console.log(error)
					alert('发生错误:==' + error)
					this.isSending5 = false
				})
		},
		caigou2(row){
			this.isSending5 = true
			this.$axios.post('/vue/parent_calculate/caigou2', row).then(res => {
				if (res.code == 200) {
					let _this = this
					this.$notify.success({
						title: '成功',
						message: res.msg,
						duration: 2000,
						onClose: function () { _this.handleClose2() }
					});
					this.isSending5 = false
				} else {
					this.$notify.error({
						title: '错误',
						message: res.msg,
						duration: 2000
					});
					this.isSending5 = false
				}
			})
				.catch(error => {
					console.log(error)
					alert('发生错误:==' + error)
					this.isSending5 = false
				})
		},
		allupdate() {
			if (this.filterText != '' && this.projectf != '') {
				this.title = '批量编辑'
				this.dialogVisible2 = true
			} else {
				alert('请先选择父体和店铺')
			}
		},
		update_data() {
			this.isSending2 = true
			console.log(this.form);
			this.$axios.post('/vue/parent_calculate/update_data', this.form).then(res => {
				if (res.code == 200) {
					let _this = this
					this.$notify.success({
						title: '成功',
						message: res.msg,
						duration: 2000,
						onClose: function () { _this.handleClose1() }
					});
					this.isSending2 = false
				} else {
					this.$notify.error({
						title: '错误',
						message: res.msg,
						duration: 2000
					});
					this.isSending2 = false
				}
			})
				.catch(error => {
					console.log(error)
					alert('发生错误:==' + '请检查内容是否填写完整')
					this.isSending2 = false
				})
		},
		updateall_data() {
			this.isSending2 = true
			console.log(this.form1);
			this.$axios.post('/vue/parent_calculate/update_alldata', { form: this.form1, data: this.tableData1 }).then(res => {
				if (res.code == 200) {
					let _this = this
					this.$notify.success({
						title: '成功',
						message: res.msg,
						duration: 2000,
						onClose: function () { _this.handleClose2() }
					});
					this.isSending2 = false
				} else {
					this.$notify.error({
						title: '错误',
						message: res.msg,
						duration: 2000
					});
					this.isSending2 = false
				}
			})
				.catch(error => {
					console.log(error)
					alert('发生错误:==' + '请检查内容是否填写完整')
					this.isSending2 = false
				})
		},

		select_pm() {
			if (this.filterText == '') {
				alert('请选择店铺')
			} else {
				this.isSending8 = true
				this.$axios.post(`/vue/parent_calculate/select_pm`, { input: this.select_input, shop: this.filterText }).then(res => {
					console.log(res)
					this.tableData1 = res.data
					this.isSending8 = false
				}).catch(error => {
					alert(error)
					console.log(error)
					this.isSending8 = false
				})
			}
		},
	}
}
</script>
  
<style>
.main {
	height: 100%;
	width: 100%;
}

.select_menu {
	width: 100%;
	height: 4.5%;
	background-color: rgb(250, 250, 250);
}

.parent_calculate {
	/* border: 1px solid red; */
	height: 88.9%;
}

.parent_calculate_div1 {
	width: 14.2%;
	height: 94%;
	margin: 20px;
	float: left;


}

.parent_calculate_div3 {
	width: 23%;
	height: 94%;
	margin: 20px;
	float: left;

}

.parent_calculate_table1 {
	width: 100%;
	border: 2px solid rgb(207, 207, 207);
	height: 92%;
	margin-top: 10px;


}

.parent_calculate_table2 {
	width: 580%;
	border: 2px solid rgb(207, 207, 207);
	height: 92%;
	margin-top: 10px;

}

.parent_calculate_table3 {
	width: 800px;
	border: 2px solid rgb(207, 207, 207);
	height: 92%;
	margin-top: 47px;
	margin-left: 48%;

}

.green-color {
	color: green;
}

.yellow-color {
	color: rgb(192, 192, 84);
}
</style>

<style scoped="scoped">
::v-deep .warm tbody tr>td {
	background-color: rgba(236, 236, 236, 0.8);
}
</style>